import styled, { css } from 'styled-components';
import NextImage from 'next/image';

import Link from 'Elements/Link';

import type { Orientation } from './Ben';

export const Container = styled.article<{ $orientation: Orientation }>`
    ${({ theme, $orientation }) => css`
        position: relative;
        padding: 0;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        &:hover ${StyledImage} {
            transform: scale(1.1);
        }

        @media ${theme.breakpoints.large.media} {
            gap: 1.5rem;
            height: 100%;

            ${$orientation === 'portrait' &&
            css`
                flex-direction: column;
                align-items: flex-start;
                gap: 0.75rem;
            `}
        }
    `}
`;

export const UpperContainer = styled.div<{ $orientation: Orientation }>`
    ${({ theme, $orientation }) => css`
        height: 5.25rem;
        position: relative;
        border-radius: 0.5rem;
        overflow: hidden;
        flex: 0 0 40%;

        @media ${theme.breakpoints.large.media} {
            ${$orientation === 'portrait' &&
            css`
                width: 100%;
                flex: 0 0 11rem;
            `}

            ${$orientation === 'landscape' &&
            css`
                height: 11rem;
                flex: 0 0 45%;
            `}
        }
    `}
`;

export const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0.6rem 0.5rem 0.5rem 0.5rem;
    overflow: hidden;
`;

export const StyledImage = styled(NextImage)`
    object-fit: cover;
    transition: transform 0.7s ease-in-out;
`;

export const Tag = styled.span<{ $type?: string }>`
    ${({ theme, $type }) => css`
        display: block;
        font-size: 0.875rem;
        color: ${theme.colors.tertiary.hex};
        background: ${theme.colors.secondary.tints[1].hex};
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem 0 0.25rem 0;
        line-height: 1;
        z-index: 1;

        ::first-letter {
            text-transform: capitalize;
        }

        ${$type === 'blogs'
            ? css`
                  color: ${theme.colors.accents[9].hex};
                  background: #eee8e9;
              `
            : $type === 'events'
            ? css`
                  color: ${theme.colors.accents[10].hex};
                  background: #ced6d7;
              `
            : css`
                  color: ${theme.colors.tertiary.hex};
                  background: ${theme.colors.secondary.tints[1].hex};
              `}

        @media ${theme.breakpoints.large.media} {
            font-size: 1rem;
            padding: 0.375rem 0.875rem;
            border-radius: 0.5rem 0 0.5rem 0;
        }
    `}
`;

export const SubjectLocation = styled.span`
    ${({ theme }) => css`
        display: block;
        font-size: 0.875rem;
        line-height: 1.55;
        color: ${theme.colors.secondary.tints[6].hex};
        margin-bottom: 0.25rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;

        :after {
            content: ' ';
            font-size: 0;
            white-space: pre;
        }

        @media ${theme.breakpoints.large.media} {
            font-size: 1rem;
        }
    `}
`;

export const Heading = styled.h3`
    ${({ theme }) => css`
        font-size: 1rem;
        line-height: 1.3;
        margin: 0;
        height: 4rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;

        @media ${theme.breakpoints.medium.media} {
            height: 4rem;
        }

        @media ${theme.breakpoints.large.media} {
            font-size: 1.5rem;
            height: 6rem;
        }
    `}
`;

export const CardLink = styled(Link)`
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    text-decoration: none;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
`;

export const Description = styled.p`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.large.media} {
            display: block;
            font-size: 1.125rem;
            font-weight: 200;
            margin: 0.75rem 0 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            -webkit-box-orient: vertical;
        }
    `}
`;

export const Date = styled.span`
    ${({ theme }) => css`
        display: block;
        font-size: 0.875rem;
        margin-top: 0.5rem;
        line-height: 1.55;
        color: ${theme.colors.secondary.tints[6].hex};

        @media ${theme.breakpoints.large.media} {
            font-size: 1rem;
        }
    `}
`;
