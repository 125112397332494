import styled, { css } from 'styled-components';

import Link from 'Elements/Link';

export const Top = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: ${theme.commonStyles.brandPixel} solid ${theme.colors.secondary.tints[3].hex};
        padding-bottom: 0;

        @media ${theme.breakpoints.large.media} {
            padding-bottom: 1rem;
        }
    `}
`;

export const Heading = styled.h2`
    ${({ theme }) => css`
        font-size: 1.25rem;
        margin: 0;

        @media ${theme.breakpoints.large.media} {
            font-size: 1.5rem;
        }
    `}
`;

export const StyledLink = styled(Link)`
    ${({ theme }) => css`
        display: none;

        @media ${theme.breakpoints.large.media} {
            display: block;
            font-size: 1.125rem;
            font-weight: 700;
        }
    `}
`;

export const Listing = styled.ul`
    ${({ theme }) => css`
        list-style-type: none;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 1.5rem;

        @media ${theme.breakpoints.medium.media} {
            gap: 0.75rem;
        }

        @media ${theme.breakpoints.large.media} {
            gap: 1.5rem;
        }
    `}
`;

export const ListItem = styled.li`
    ${({ theme }) => css`
        grid-column-start: span 12;

        @media ${theme.breakpoints.medium.media} {
            grid-column-start: span 6;
        }

        @media ${theme.breakpoints.large.media} {
            grid-column-start: span 3;
        }
    `}
`;
