import React from 'react';
import { StaticImageData } from 'next/image';
import { format } from 'date-fns';

import { LinkProps } from 'Elements/Link';

import {
    Container,
    UpperContainer,
    ImageContainer,
    StyledImage,
    Tag,
    SubjectLocation,
    Heading,
    CardLink,
    Description,
    Date
} from './Ben.styled';

export type Orientation = 'portrait' | 'landscape';

export interface Props {
    id: string;
    image: {
        alt?: string;
        src: StaticImageData | string;
    };
    tag?: string;
    heading: string;
    description: string | undefined;
    date: Date;
    subject?: string;
    location?: string;
    link: LinkProps;
    orientation?: Orientation;
}

const Ben: React.FC<Props> = props => {
    const {
        id,
        image,
        tag,
        heading,
        description,
        date,
        subject,
        location,
        link,
        orientation = 'portrait'
    } = props;

    return (
        <Container className="c-card-ben" $orientation={orientation}>
            <UpperContainer $orientation={orientation}>
                {tag && <Tag $type={tag}>{tag}</Tag>}
                <ImageContainer>
                    <StyledImage src={image.src} alt={image.alt || ''} fill />
                </ImageContainer>
            </UpperContainer>
            <div>
                <SubjectLocation>{subject || location || ' '}</SubjectLocation>
                <Heading>
                    <CardLink id={id} href={link.href} aria-label={`Read more on ${heading}`}>
                        {heading}
                    </CardLink>
                </Heading>
                {description && <Description>{description}</Description>}
                <Date>{format(date, 'd MMMM y')}</Date>
            </div>
        </Container>
    );
};

export default Ben;
