import styled, { css } from 'styled-components';
import NextImage from 'next/image';
import { Play } from '@solent-university/solent-icons';

export const Container = styled.article`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        &:hover {
            ${StyledImage} {
                transform: scale(1.2);
            }

            ${PlayWrapper} {
                transform: translate(-50%, -50%) scale(0.9);
                filter: brightness(110%);
            }
        }

        @media ${theme.breakpoints.large.media} {
            flex-direction: column;
            gap: 1rem;
        }
    `}
`;

export const ImageContainer = styled.div`
    ${({ theme }) => css`
        height: 5.25rem;
        position: relative;
        border-radius: 0.5rem;
        overflow: hidden;
        flex: 0 0 40%;

        @media ${theme.breakpoints.large.media} {
            flex: 1 0 11rem;
            width: 100%;
        }
    `}
`;

export const StyledImage = styled(NextImage)`
    object-fit: cover;
    transition: transform 0.7s ease-in-out;
`;

export const PlayWrapper = styled.div`
    ${({ theme }) => css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: ${theme.colors.primary.hex};
        width: 2rem;
        height: 2rem;
        z-index: 1;
        display: grid;
        place-items: center;
        transition: transform 0.7s ease-in-out;

        @media ${theme.breakpoints.large.media} {
            width: 4rem;
            height: 4rem;
        }
    `}
`;

export const StyledPlay = styled(Play)`
    ${({ theme }) => css`
        height: 0.8rem;
        width: auto;
        fill: ${theme.colors.primaryContrast.hex};
        margin-left: 0.175rem;

        @media ${theme.breakpoints.large.media} {
            height: 1.75rem;
            margin-left: 0.25rem;
        }
    `}
`;
