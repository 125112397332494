import React from 'react';
import { StaticImageData } from 'next/image';

import { ArrowLinkButtonProps } from 'Elements/Button/Arrow';

import {
    Container,
    ImageContainer,
    StyledImage,
    Wave,
    Inner,
    Heading,
    Description,
    StyledArrowButton
} from './Listing.styled';

export interface Props {
    image: {
        alt?: string;
        src: StaticImageData | string;
    };
    heading: string;
    description: string;
    button: ArrowLinkButtonProps;
}

const Listing: React.FC<Props> = props => {
    const { image, heading, description, button } = props;

    return (
        <Container>
            <ImageContainer>
                <StyledImage src={image.src} alt={image.alt || ''} width={312} height={184} />
                <Wave />
            </ImageContainer>
            <Inner>
                <Heading>{heading}</Heading>
                <Description>{description}</Description>
                <StyledArrowButton {...button} />
            </Inner>
        </Container>
    );
};

export default Listing;
