import React from 'react';
import { StaticImageData } from 'next/image';

import { LinkProps } from 'Elements/Link';

import { Heading, CardLink, SubjectLocation, Date } from '../Ben.styled';

import { Container, ImageContainer, StyledImage, PlayWrapper, StyledPlay } from './Video.styled';

export interface Props {
    id: string;
    image: {
        alt?: string;
        src: StaticImageData | string;
    };
    heading: string;
    subject?: string;
    length: number;
    link: LinkProps;
}

const Video: React.FC<Props> = props => {
    const { id, image, heading, subject, length, link } = props;

    return (
        <Container>
            <ImageContainer>
                <PlayWrapper>
                    <StyledPlay />
                </PlayWrapper>
                <StyledImage src={image.src} alt={image.alt || ''} fill />
            </ImageContainer>
            <div>
                <SubjectLocation>{subject || ' '}</SubjectLocation>
                <Heading>
                    <CardLink id={id} href={link.href} aria-label={`Watch ${heading}`}>
                        {heading}
                    </CardLink>
                </Heading>
                <Date>{length} minutes</Date>
            </div>
        </Container>
    );
};

export default Video;
